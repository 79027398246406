//*----------  COMPONENTS  ----------*/
import DataGrid from '@/components/DataGrid/DataGridSimple/DataGridSimple.vue'
//*----------  VUEX  ----------*/
import service        from '@/services/soporte'
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex'

export default {
    name : "TabRequerimiento",
    components : {
        DataGrid
    },
    data : () => ({
        configGrid : {
            summary : true,
            paging : {
                properties : {
                    "page-size" : 25
                }
            },
            export : {
                export          : true,
                exportSelected  : true,
                exportName      : "Requerimientos",
                texts           : { exportAll : "Exportar toda la información", exportSelectedRows  : "Exportar filas seleccionadas" } 
            },
        },
        actions : [],
        dataColumns: [
            {
                "data-field"    : "id",
                caption         : "ID",
                column          : "idDt",
            }, {
                "data-field"  : "requerimiento",
                caption       : "Requerimiento",
                column        : "requerimiento",
            }, {
                "data-field"  : "descripcion",
                caption       : "Descripción",
                column        : "descripcion",
            }, {
                "data-field"  : "active",
                caption       : "Activo",
                column        : "active",
            }, {
                "data-field" : "fechaC",
                caption      : "Fecha Creación",
                column       : "fechaC",
            }, {
                "data-field" : "userC",
                caption      : "Usuario Creación",
                column       : "userC",
            }, {
                "data-field" : "fechaU",
                caption      : "Fecha Modificación",
                column       : "fechaU",
            }, {
                "data-field" : "userU",
                caption      : "Usiario Modificación",
                column       : "userU",
            }
        ],
        formatExcel     : {
            fileName    : 'requerimientos.xlsx',
            worksheet   : 'requerimientos',
            salidas     : {
                factor : 1,
                factorG: 1
            },
        }
    }),
    computed : {
       ...mapGetters("Requerimiento", {
           dataSource : "getDataSource"
        }),
    },
    methods : {
        ...mapActions("Requerimiento",  ["setDataSource"] ),
        eventActionTable({ event, dataAction }) {
            this.$emit("eventActionTable", { event, dataAction })
        },
    },
    created() {
        service.SopR_dataSourceRequerimiento().then( result => {
            this.setDataSource( this.$JsTable.toObj( result.data ))
        })
    },
}
